import mixitup from 'mixitup';

export const mixitupConfig = () => {
  if (document.querySelector("#blog")) {
    // mixer options
    let mixer = mixitup(".blogue-card", {
     load: {
       filter: ".carte-centre-information"
     },
     animation: {
       duration: 450,
       nudge: true,
       reverseOut: true,
       effects: "fade scale(0.77) translateZ(-68px) stagger(6ms)"
     }
   });
 
   // 🍧 handle hash URL filtering
   (function setHashFromFilter() {
       let filterValue,
           filterValueCleaned,
           filterFromHash;
 
       let filters = document.querySelectorAll(".filter");
       for (let i = 0; i < filters.length; i++) {
         let filter = filters[i];
         filter.addEventListener("click", event => {
           // get the "data-filter" respectively "data-sort" attribute         
           if (event.currentTarget.hasAttribute("data-filter")) {
             filterValue = event.currentTarget.getAttribute("data-filter");
           }        
           // handle the "all" data-filter edge case
           if (!filterValue.includes(".")) {
             filterValue = `.${filterValue}`;
           }
           filterValueCleaned = filterValue.split(".")[1];    
           location.hash = "filtre=" + encodeURIComponent(filterValueCleaned);
         }); // end listener
 
         // 🍪 if hash exists
         if (location.hash) {
           // handling of the data-filtre="all" missing the "."
           if (location.hash == "#filtre=all") {
             filterFromHash = "all";
           } else {
             filterFromHash = location.hash.replace("#filtre=", ".");
           } // end if
           // update mixer on hash exists
           mixer.filter(filterFromHash);
           // handle button state on hash exists
           if (document.querySelector(".filter.mixitup-control-active") && document.querySelector(`[data-filtre="${filterFromHash}"]`)) {
             let oldFilter = document
             .querySelector(".filter.mixitup-control-active")
             .classList.remove("mixitup-control-active");
             let newFilter = document
             .querySelector(`[data-filtre="${filterFromHash}"]`)
             .classList.add("mixitup-control-active");
           }
         }
       } // end for loop
     })(); // end setHashFromFilter()
   }
}
  