// GSAP animation scripts below
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import SplitText from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, DrawSVGPlugin, SplitText);

// Animation scripts //
// plusdici.ca //

// A) Buttons
// B) fadeUp (gsap)
// C) fadeIn (gsap)
// D) Unfold (gsap)

export const animations = () => {
    // A) BOUTONS (and their crosses) //
    // Custom svg
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" class="btn-icon" viewBox="0 0 206.755 206.992"><path data-name="croix" d="M78.042,0l4.033,1.994L96.916,45.7l2.417,1.174,43.108-14.62,4.031,1.994,14.733,43.374-2,4.054L116.125,96.319l-1.2,2.414,14.843,43.685-1.995,4.054L83.183,161.6l-4.055-1.994-14.84-43.684-2.415-1.2L18.786,129.37l-4.053-2.016L0,83.979l1.994-4.054L45.1,65.306l1.175-2.415L31.457,19.184l1.994-4.032Z" transform="translate(55.271) rotate(20)"/></svg>`;
    let bigButtons = document.querySelectorAll('.big-btn');

    // This function replace the cross color with the current text color
    let setSvgColor = (element) => {
        setTimeout(() => {
            let textElement = element.querySelector("div");
            if (textElement) { // Si c'est bien un bouton avec du texte
                let color = window.getComputedStyle(textElement).getPropertyValue("color");
                element.querySelector("path").setAttribute("fill", color);
            }
        }, 160);
    };

    // If buttons are on the page, change their svg and add events so they change color on hover
    if (bigButtons) {
        for (let currentBtn of bigButtons) {
            let imgToRemove = currentBtn.querySelector("img");
            if (imgToRemove) {
                currentBtn.addEventListener('mouseover', () => setSvgColor(currentBtn));
                currentBtn.addEventListener('mouseout', () => setSvgColor(currentBtn));
                // Replace the image with custom svg
                imgToRemove.remove();
                currentBtn.innerHTML = currentBtn.innerHTML + svg;
                setSvgColor(currentBtn);
            }
        }
    }

    // B) FADE-UP
    gsap.registerEffect({
        name: "fadeUp",
        effect: (target) => {
          return gsap.from(target, {
            scrollTrigger: {
              trigger: target,
              start: "top-=50% bottom"
            },
            yPercent: 75,
            autoAlpha: -2,
            transformOrigin: "left top",
            duration: .6
          });
        }
      });

      let fadeUpElements = document.querySelectorAll('[data-gsap="fade-up"]');
      if (fadeUpElements) {
        for (let current of fadeUpElements) {
          gsap.effects.fadeUp(current);
        }
      }

    // C) FADE-IN
    gsap.registerEffect({
      name: "fadeIn",
      effect: (target) => {
        return gsap.from(target, {
          scrollTrigger: {
            trigger: target,
            start: "top+=15% bottom"
          },
          autoAlpha: 0,
          ease: "power3.out",
          duration: .5
        });
      }
    });

    let fadeInElements = document.querySelectorAll('[data-gsap="fade-in"]');
    if (fadeInElements) {
      for (let current of fadeInElements) {
        gsap.effects.fadeIn(current);
      }
    }

    // D) UNFOLD
    gsap.registerEffect({
      name: "unfold",
      effect: (target) => {
        return gsap.from(target, {
          scrollTrigger: {
            trigger: target,
            start: "top bottom-=25%"
          },
          xPercent: 100,
          autoAlpha: 1,
          transformOrigin: "left",
          duration: 1
        });
      }
    });

    let unfoldElements = document.querySelectorAll('.avantage-left');
    if (unfoldElements) {
      for (let current of unfoldElements) {
        if (window.innerWidth > 991) {
          gsap.effects.unfold(current);
        }
      }
    }
}