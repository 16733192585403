// Countdown script //

export const countdown = () => {
  let countdownWrapper = document.querySelector('.countdown-wrapper');
  if (countdownWrapper) {
    // Elements
    let currentTimestampHolder = countdownWrapper.querySelector('[data-now-time]');
    let countdownTimestampHolder = countdownWrapper.querySelector('[data-countdown-time]');
    let currentTimestamp = currentTimestampHolder.getAttribute('data-now-time');
    let countdownTimestamp = countdownTimestampHolder.getAttribute('data-countdown-time');
    let daysHolder = document.querySelector('[data-countdown="days"]');
    let hoursHolder = document.querySelector('[data-countdown="hours"]');
    let minutesHolder = document.querySelector('[data-countdown="minutes"]');
    
    // Hide data bearers
    currentTimestampHolder.style.display = "none";
    countdownTimestampHolder.style.display = "none";

    // Hide whole countdown if date is in the past or of date is absent
    if (currentTimestamp >= countdownTimestamp) {
      countdownWrapper.style.display = "none";
    } else {
      // Get days
      let days = daysHolder.textContent; // Calculated by twig
      if (days < 1) {
        daysHolder.nextElementSibling.textContent = daysHolder.nextElementSibling.textContent.slice(0, -1);
      }

      // Get the number of seconds left if we substract the remaining days
      let secondsRemaining = countdownTimestamp - currentTimestamp - days * 86400;

      // Get hours
      let hours = Math.floor(secondsRemaining / 3600);
      hoursHolder.textContent = (hours < 0) ? 0 : hours;
      if (hours <= 1) {
        hoursHolder.nextElementSibling.textContent = hoursHolder.nextElementSibling.textContent.slice(0, -1);
      }

      // Get minutes
      let minutes = Math.floor((secondsRemaining - hours * 3600) / 60);
      minutesHolder.textContent = (minutes < 0) ? 0 : minutes;
      minutesHolder.textContent = minutes;
      if (minutes <= 1) {
        minutesHolder.nextElementSibling.textContent = minutesHolder.nextElementSibling.textContent.slice(0, -1);
      }
    }
  }
}
