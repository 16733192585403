//// JS MAIN FILE ////

// Import modules
import { mixitupConfig } from "./modules/_mixitup-config.js";
mixitupConfig();

// Import Webflow
import { webflow } from './modules/_webflow.js';
webflow();

import { countdown } from "./modules/_countdown.js";
countdown();

// Import templates

// Import gsap
import { animations } from './modules/_gsap.js';
animations();